<template>
  <div class="w-full flex flex-col bg-gray-50 h-full relative">
    <div class="overflow-y-auto bg-gray-50">
      <div class="align-middle inline-block min-w-full overflow-hidden">
        <div class="h-16  flex flex-row justify-between">
          <div class="w-full pt-4 h-16">
            <div class="absolute left flex flex-row ml-4">
              <button type="button" @click="toggleCheck" class="inline-flex items-center mr-3 px-4 py-1 text-sm leading-5 font-medium rounded border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                <div v-if="checkFlag"><span><span class="far fa-check-square"></span></span></div>
                <div v-else><span><span class="far fa-square"></span></span></div>
              </button>
              <combo-button :title="columnView" v-on:select-item="selectView" :z="10" :items="views.map(v => { return {item: v, name: v.title, key: v.id, icon: null, counter: null, separator: false}})" />
            </div>
            <div class="absolute right-0 flex flex-row mr-4">
              <combo-button :title="sortItem === null ? 'Sort' : sortItem.name" :right="true" :items="selectColumns()" v-on:select-item="selectSort" class="ml-2" />
              <button type="button" @click="sortFlag=!sortFlag" class="inline-flex items-center ml-3 px-2 py-1 border border-gray-300 text-sm leading-5 font-medium rounded border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                <div v-if="sortFlag"><span><span class="fas fa-sort-amount-down"></span></span></div>
                <div v-else><span><span class="fas fa-sort-amount-up"></span></span></div>
              </button>
            </div>
          </div>
        </div>
        <div class="z-0 mx-4 overflow-x-auto border border-gray-200 bg-white rounded-lg overflow-y-auto relative"
             :style="{height: tableHeight  + 'px'}">
          <table class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
            <thead>
            <tr class="text-left">
              <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-white">
                <label
                    class="text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer">
                  <input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded" v-model="selectAll">
                </label>
              </th>
              <th class="bg-white sticky top-0 border-b border-gray-200 px-2 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">ID</th>
              <th v-for="col in columns" v-bind:key="col.id"  :style="{minWidth: col.width + 'px' }" class="bg-white sticky top-0 border-b border-gray-200 px-2 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">{{ col.title }}</th>
            </tr>
            </thead>
            <tbody v-if="items.length>0">
              <tr v-for="item in items" v-bind:key="item.id" @click="edit(item)" :class="[selectedId === item.id ? 'bg-blue-500 text-white' :  (selected.includes(item.id) ? 'hover:bg-blue-50 bg-blue-50' : 'hover:bg-blue-50 bg-white'),'cursor-pointer']">
                <td class="border-dashed border-t border-gray-200 px-3">
                  <label class="inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer">
                    <input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded" :value="item.id" v-model="selected">
                  </label>
                </td>
                <td class="border-dashed border-t border-gray-200 userId">
                  <span v-if="columnView==='General'">
                    {{item.id}}<br/><span class="text-gray-300">{{item.vehicle_id}}</span>
                  </span>
                      <span v-else>
                      {{item.id}}
                  </span>
                </td>
                <td v-for="col in columns" v-bind:key="col.id" :style="{minWidth: col.width + 'px' }" :class="[selectedId === item.id ? 'text-white' : 'text-gray-500' , 'border-dashed border-t border-gray-200']">
                  <component :is="col.component" :column="col.id" :item="item" v-on:select-item="select" />
                </td>
              </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td :colspan="Object.keys(columns).length + 2">
                <div v-if="loading" class="flex flex-row justify-center items-center p-2 font-bold text-2xl text-gray-400 my-8">
                  <div><span><span class="fas fa-spinner-third fa-spin"></span></span></div>
                  <div class="ml-4">Loading</div>
                </div>
                <div v-else class="flex flex-row justify-center items-center p-2 font-bold text-2xl">No records found</div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="absolute px-4 py-2 w-full h-14 static bottom-0 bg-gray-50 text-gray-800">
      <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p class="text-sm text-gray-700">
            Showing
            {{ ' ' }}
            <span class="font-medium">1</span>
            {{ ' ' }}
            to
            {{ ' ' }}
            <span class="font-medium">10</span>
            {{ ' ' }}
            of
            {{ ' ' }}
            <span class="font-medium">97</span>
            {{ ' ' }}
            results
          </p>
        </div>
        <div>
          <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <a href="#" class="relative inline-flex items-center justify-center w-12 px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
              <span class="sr-only">Previous</span>
              <i class="fa fa-chevron-left"></i>
            </a>
            <!-- Current: "z-10 bg-blue-50 border-blue-500 text-blue-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
            <a href="#" aria-current="page" class="z-10 bg-blue-50 border-blue-500 text-blue-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium">
              1
            </a>
            <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">
              2
            </a>
            <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium">
              3
            </a>
            <span class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
            ...
          </span>
            <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium">
              8
            </a>
            <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">
              9
            </a>
            <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">
              10
            </a>
            <a href="#" class="relative inline-flex items-center justify-center w-12 px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
              <span class="sr-only">Next</span>
              <i class="fa fa-chevron-right"></i>
            </a>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import comboButton from '@/components/combo-button'
import fleetColumn from '../../../master/views/columns/base-column'
import fleetColumnDescription from '../columns/fleet-column-description'
import fleetColumnDescriptionSmall from '../columns/fleet-column-description-small'
import fleetColumnDestination from '../columns/fleet-column-destination'
import fleetColumnDestinationSmall from '../columns/fleet-column-destination-small'
import fleetColumnExpected from '../columns/fleet-column-expected'
import fleetColumnIdentification from '../columns/fleet-column-identification'
import fleetColumnIdentificationSmall from '../columns/fleet-column-identification-small'
import fleetColumnKm from '../columns/fleet-column-km'

export default {
  name: "Main",
  props: {},
  components: {
    'combo-button': comboButton,
    'fleet-column': fleetColumn,
    'fleet-column-description': fleetColumnDescription,
    'fleet-column-description-small': fleetColumnDescriptionSmall,
    'fleet-column-destination': fleetColumnDestination,
    'fleet-column-destination-small': fleetColumnDestinationSmall,
    'fleet-column-expected': fleetColumnExpected,
    'fleet-column-identification': fleetColumnIdentification,
    'fleet-column-identification-small': fleetColumnIdentificationSmall,
    'fleet-column-km': fleetColumnKm,
  },
  data() {
    const defaultColumns = {
      location: {width: 100, id: 'location', title: 'Location', component: 'fleet-column-destination'},
      driver: {width: 200, id: 'driver', title: 'Driver', component: 'fleet-column'},
      expected: {width: 100, id: 'exp_date', title: 'Expected', component: 'fleet-column-expected'},
      description: {width: 600, id: 'description', title: 'Description', component: 'fleet-column-description'},
      identification: {width: 200, id: 'vin', title: 'Identification', component: 'fleet-column-identification'},
      km: {width: 100, id: 'km', title: 'Km', component: 'fleet-column-km'},
    };
    return {
      loading: true,
      columns: defaultColumns,
      columnView: 'General',
      selectedId: null,
      views: [{
        id: 'general',
        title: 'General',
        columns: defaultColumns
      }, {
        id: 'small',
        title: 'Small',
        columns: {
          location: {width: 100, id: 'location', title: 'Location', component: 'fleet-column-destination-small'},
          driver: {width: 200, id: 'driver', title: 'Driver', component: 'fleet-column'},
          expected: {width: 100, id: 'exp_date', title: 'Expected', component: 'fleet-column-expected'},
          description: {
            width: 600,
            id: 'description',
            title: 'Description',
            component: 'fleet-column-description-small'
          },
          identification: {
            width: 200,
            id: 'vin',
            title: 'Identification',
            component: 'fleet-column-identification-small'
          },
          km: {width: 100, id: 'km', title: 'Km', component: 'fleet-column-km'},
        }
      }],
      checkFlag: false,
      sortFlag: false,
      sortItem: null,
      selectAll: false,
      selected: [],
      items: [],
      tableHeight: window.innerHeight - 206
    }
  },
  methods: {
    edit(item) {
      if (this.checkFlag) {
        if (this.selected.filter(s => s === item.id).length > 0) {
          this.selected = this.selected.filter(s => s !== item.id)
        } else {
          this.selected.push(item.id);
        }
        return
      }
      this.selectedId = item.id;
      this.$store.commit("sales/openItem", item.id);
    },
    select(item) {
      this.$emit('select-item', item)
    },
    selectSort(item) {
      this.sortItem = item;
    },
    selectColumns() {
      return Object.keys(this.columns).map(c => {
        return {
          item: c,
          name: this.columns[c].title,
          key: this.columns[c].id,
          counter: null,
          icon: null,
          separator: false
        }
      });
    },
    toggleCheck() {
      this.checkFlag = !this.checkFlag;
    },
    selectView(view) {
      this.columns = view.item.columns;
      this.columnView = view.item.title;
    },
    resizeWindow() {
      this.tableHeight = window.innerHeight - 206;
    }
  },
  watch: {
    selectAll: function (val) {
      if (val) {
        this.selected = this.items.map(i => i.id);
      } else {
        this.selected = [];
      }
    }
  },
  created() {
    this.loading = true;
    const url = "/manager/seller/leadListData/7/";
    const params = {
      errorFlag: 0,
      plus: 0,
      part: 'waiting',
      searchTerm: '',
      _search: false,
      rows: 50,
      page: 1,
      sidx: '',
      sord: 'asc'
    }
    axios.get(url, {params: params}).then(response => {
      this.page = response.data.page
      this.totalPages = response.data.total
      this.items = response.data.rows
      this.loading = false;
    });
    window.addEventListener("resize", this.resizeWindow);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindow);
  },
}
</script>

<style scoped>

</style>



